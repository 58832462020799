import React from "react";
import routes from "./allRoutes";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Layout from "../Layout/Index";

// render app route pages
const AppRoute = ({ component, path, ...rest }) => {
  const Component = component;
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

const Index = () => {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {routes.map((route, idx) => (
            <AppRoute path={route.path} component={route.component} key={idx} />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default Index;



